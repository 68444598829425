<template>
  <div class="container" v-loading="loading">
    <div class="row course-part">
      <div class="update-title col-xs-12 col-sm-12 col-md-12 center">
        创意源-大版本更新
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="block page-body">
          <el-timeline>
            <el-timeline-item timestamp="2022-06-03" placement="top">
              <el-card>
                <h4>上线创意源2.1：基于版本2.0的计划，重构系统</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021-10-04" placement="top">
              <el-card>
                <h4>上线创意源2.0：重构系统</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2019-10-28" placement="top">
              <el-card>
                <h4>上线创意源1.0：创意源诞生</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["user"],
  data() {
    return {
      list: null,
      loading: true,
    };
  },
  created() {
    // this.getPageData()
    this.loading = false;
  },
  methods: {
    async getPageData() {
      const response = await axios.post("/api/web/page/details/index");
      const items = Object.values(response.data.data.content);
      this.sliders = items[0];
      // console.log(this.sliders)
      // this.loading = false
    },
  },
};
</script>
